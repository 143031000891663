import { useEffect } from 'react'; // added
import { getAuth } from 'firebase/auth';
import useAuthHook from '../../hooks/useAuthHook';

function GameRenderer() {
    const { loggedIn, authLoading } = useAuthHook();

    useEffect(() => {
        if (!authLoading) {
            const iframe = document.querySelector('iframe');
            if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
                // Mobile device style: fill the whole browser client area with the game canvas:
                var meta = document.createElement('meta');
                meta.name = 'viewport';
                meta.content = 'width=device-width, height=device-height, initial-scale=1.0, user-scalable=no, shrink-to-fit=yes';
                document.getElementsByTagName('head')[0].appendChild(meta);

                iframe.style.width = "100%";
                iframe.style.height = "100%";
                iframe.style.position = "fixed";

                document.body.style.textAlign = "left";
            } else {
                const height = window.innerHeight - 70;
                iframe.style.height = `${height}px`;
                iframe.style.width = `${height * (9 / 16)}px`;
            }


            window.onmessage = function (e) {
                if (e.data === 'loaded') {
                    iframe.contentWindow.postMessage({ type: 'USERID', payload: getAuth().currentUser?.uid || "DEFAULT" }, '*');
                }
            };
        }
    }, [loggedIn, authLoading]);

    // If our auth state is loading we want to render null
    // This happens when the user refreshes the page
    if (authLoading) {
        return null;
    }

    return (
        <iframe title='8BitEvilReturns' src="https://scareathongametest.scarbone98.repl.co/" frameborder="0"></iframe>
    );
}

export default GameRenderer;