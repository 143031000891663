import { useEffect, useState } from 'react';
import 'moment-countdown';
import moment from 'moment';

export default function Timer(targetDate) {


    const [timer, setTimer] = useState(moment().countdown(targetDate).toString());

    const isPastTargetDate = moment().isAfter(moment(targetDate));


    useEffect(() => {
        const interval = setInterval(() => {
            if (!isPastTargetDate) {
                setTimer(moment().countdown(targetDate).toString());
            } else {
                clearInterval(interval);
            }
        }, 1000);
        return () => {
            if (interval) {
                clearInterval(interval);
            }
        }
    }, [targetDate, isPastTargetDate]);

    return { timer, isPastTargetDate };

}