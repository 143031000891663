import { getAuth } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { Flex } from 'reflexbox';
import Playing from '../../../assets/images/playing.png';
import Muted from '../../../assets/images/muted.png';
import Loading from '../../loading/Loading';
import './_leaderBoard.scss';

export default function LeaderBoardModal({ playAgainCallback, signOutOfAccountCallback, score }) {

    let baseURL = '';

    if (process.env.NODE_ENV !== 'development') {
        baseURL = process.env.REACT_APP_API_URL;
    } else {
        baseURL = 'http://localhost:8080';
    }

    const auth = getAuth();
    const [leaderBoard, setLeaderBoard] = useState({ loading: true, data: [] });
    const [songMuted, setSongMuted] = useState(false);

    useEffect(() => {
        async function submitData() {

            // CHECK IF USER MUTED THE SONG
            const songState = localStorage.getItem('songState');
            if (songState) {
                setSongMuted(songState === 'muted');
            }

            let scores = [];
            try {
                const response = await fetch(`${baseURL}/getAllScores`);
                scores = await response.json();
            } catch (err) {
                console.log(err);
            }

            let scoresWithCurrent = [...scores.data];

            if (score > 0 || scores.data.length < 10) {
                scoresWithCurrent = [{ score, userId: auth.currentUser.displayName }, ...scoresWithCurrent];
            }

            scoresWithCurrent.sort((a, b) => b.score - a.score);

            setLeaderBoard({ loading: false, data: scoresWithCurrent.slice(0, 10) });

            if (score > 0) {
                try {
                    await fetch(`${baseURL}/addScore`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ userId: auth.currentUser.displayName, score })
                    });
                } catch (err) {
                    console.log(err);
                }
            }
        }
        submitData();
    }, []);

    return (
        <div className="leader-board">
            <div className="leader-board-content-container" style={{ backgroundColor: leaderBoard.loading ? 'transparent' : 'rgb(177, 10, 10)' }}>
                {
                    !leaderBoard.loading &&
                    <div
                        style={{ position: 'absolute', top: 15, right: 15 }}
                        onClick={
                            () => {
                                if (global.customFunctions?.musicObject) {
                                    if (global.customFunctions.musicObject.isPlaying) {
                                        global.customFunctions.musicObject.pause();
                                        setSongMuted(true);
                                        localStorage.setItem('songState', 'muted');
                                    } else {
                                        global.customFunctions.musicObject.play();
                                        setSongMuted(false);
                                        localStorage.setItem('songState', 'playing');
                                    }
                                }
                            }
                        }
                    >
                        {songMuted ? <img src={Muted} /> : <img src={Playing} />}
                    </div>
                }
                {
                    leaderBoard.loading ?
                        <Loading /> :
                        (
                            <div>
                                <h2 style={{ textAlign: 'center', marginTop: 0 }}>LEADERBOARD</h2>
                                <div className="scores-grid">
                                    <>
                                        <div w={1} style={{ textAlign: 'start', color: 'black', marginBottom: '1rem' }}>
                                            RANK
                                        </div>
                                        <div w={1} style={{ textAlign: 'center', color: 'black', marginBottom: '1rem' }}>
                                            USER
                                        </div>
                                        <div w={1} style={{ textAlign: 'end', color: 'black', marginBottom: '1rem' }}>
                                            SCORE
                                        </div>
                                    </>
                                    {
                                        leaderBoard.data.map(
                                            ({ score, userId }, index) => {
                                                return (
                                                    <>
                                                        <div w={1} className="cell" style={{ textAlign: 'start' }}>
                                                            {index + 1}
                                                        </div>
                                                        <div w={1} className="cell" style={{ textAlign: 'center' }}>
                                                            {userId}
                                                        </div>
                                                        <div w={1} className="cell" style={{ textAlign: 'end' }}>
                                                            {(score || 0).toLocaleString()}
                                                        </div>
                                                    </>
                                                )
                                            }
                                        )
                                    }
                                </div>
                                <h2>Your score: {(score || 0).toLocaleString()}</h2>
                                <Flex justifyContent="space-between">
                                    <div onClick={() => playAgainCallback()} className="play-again-button">PLAY AGAIN</div>
                                    <div onClick={() => signOutOfAccountCallback()} className="sign-out-button">Sign Out</div>
                                </Flex>
                            </div>
                        )
                }
            </div>
        </div>
    )
}