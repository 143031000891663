import './_8BitEvil.scss'
import { useState, useEffect } from 'react';
import { MainContainer, ContentContainer, Navigator, EightBitEvil } from '../../../components';
import LeaderBoardModal from '../../../components/game/leaderboard/LeaderBoardModal';

function GameRederer() {
    const [modalState, setModalState] = useState({ visible: false, playAgainCallback: () => { }, score: 0 });

    useEffect(() => {
        if (!global.customFunctions) {
            global.customFunctions = {};
        }
        // THIS SHIT IS BLACK MAGIC DO NOT TOUCH
        global.customFunctions.triggerLeaderBoardModal = (score, playAgainCallback) => triggerModal(score, playAgainCallback);
    }, []);

    function triggerModal(score, playAgainCallback) {
        setModalState({ visible: true, playAgainCallback, score });
    }

    return (
        <MainContainer className="arcade-container">
            <Navigator />
            <ContentContainer>
                <EightBitEvil />
                {
                    modalState.visible &&
                    <LeaderBoardModal
                        playAgainCallback={
                            () => {
                                const playAgainCallback = modalState.playAgainCallback;
                                playAgainCallback();
                                setModalState({ visible: false, playAgainCallback: () => { }, score: 0 });
                            }
                        }
                        score={modalState.score}
                    />
                }
            </ContentContainer>
        </MainContainer>
    );
}

export default GameRederer;