import useFetch from '../../hooks/useFetch';
import { Calendar, MainContainer, ContentContainer, Navigator } from '../../components';
import { Flex } from 'reflexbox';
import './_calendar.scss';

function CalendarView() {

    const { loading, data: calendarData, error } = useFetch('/getCalendar');

    //TODO NEED TO FIX THE LOADING OF IMAGES IT'S TOO INEFFICIENT
    function renderCustomCalendarDate(date, index) {
        if (!date || !calendarData) return null;
        const movieData = calendarData.data[index] || {};
        const { title, lowResUrl } = movieData;
        const currentDay = new Date().getDate();
        return (
            <div style={{ position: 'relative', height: '100%', width: 90, opacity: date < currentDay ? 0.5 : 1, }} title={title}>
                <div style={{ position: 'absolute', left: 5, background: currentDay === date ? 'orange' : 'black', padding: 5 }}>
                    {date}
                </div>
                <Flex style={{ height: '100%' }} flexDirection="column" alignItems="center" justifyContent="space-around">
                    <img alt="poop" style={{ minWidth: '100%', height: 135, borderRadius: 7.5, border: currentDay === date ? '2px solid orange' : '1px solid black' }} src={lowResUrl} />
                </Flex>
            </div>
        )
    }

    function renderCustomCalendarDateMobile(date, index) {
        if (!date || !calendarData) return null;
        const movieData = calendarData.data[index] || {};
        const { title, lowResUrl } = movieData;
        const currentDay = new Date().getDate();
        return (
            <div>
                <Flex style={{ color: 'white' }} justifyContent="flex-end">
                    <div>
                        {`${date} ${(index + 1).toString().padStart(2, '0')}`}
                    </div>
                </Flex>
                <div style={{ position: 'relative', minHeight: 200, width: window.innerWidth / 3, opacity: index + 1 < currentDay ? 0.5 : 1 }} title={title}>
                    <Flex style={{ height: '100%' }} flexDirection="column" alignItems="center" justifyContent="space-around">
                        <img alt="poop" style={{ height: 200, borderRadius: 7.5, border: currentDay === (index + 1) ? '2px solid orange' : '1px solid black' }} src={lowResUrl} />
                    </Flex>
                </div>
            </div>
        )
    }

    return (
        <MainContainer className="calender-container">
            <Navigator />
            <ContentContainer isLoading={loading} style={{ paddingTop: '1rem' }}>
                <Calendar
                    renderCustomCalendarDateMobile={(date, index) => renderCustomCalendarDateMobile(date, index)}
                    renderDate={(date, index) => renderCustomCalendarDate(date, index)}
                />
            </ContentContainer>
        </MainContainer>
    );

}

export default CalendarView;
