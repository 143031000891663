import { initializeApp } from 'firebase/app';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail, getAuth, signOut, updateProfile } from 'firebase/auth';
import * as firestore from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyDzA4XFSTAh3Z58JOMNs1C6J58k_x3A_TY",
    authDomain: "scareathon-b36be.firebaseapp.com",
    projectId: "scareathon-b36be",
    storageBucket: "scareathon-b36be.appspot.com",
    messagingSenderId: "250391469945",
    appId: "1:250391469945:web:15b04113038805838219a8",
    measurementId: "G-YXFYZLPTG8"
};

initializeApp(firebaseConfig);

const signInEmailAndPassword = async (userName, password) => {
    try {
        const auth = getAuth();
        await signInWithEmailAndPassword(auth, userName + "@gmail.com", password);
    } catch (err) {
        console.error(err);
        alert(err.message);
    }
};

const registerWithEmailAndPassword = async (userName, password) => {
    try {
        const auth = getAuth();
        await createUserWithEmailAndPassword(auth, userName + "@gmail.com", password);
        updateProfile(auth.currentUser, {
            displayName: userName
        })
    } catch (err) {
        console.error(err);
        alert(err.message);
    }
};

const resetPassword = async (email) => {
    try {
        await sendPasswordResetEmail(email);
        alert("Password reset link sent!");
    } catch (err) {
        console.error(err);
        alert(err.message);
    }
};

const signOutOfAccount = async () => {
    try {
        const auth = getAuth();
        await signOut(auth);
    } catch (err) {
        alert(err.message);
    }
}

export {
    signInEmailAndPassword,
    registerWithEmailAndPassword,
    resetPassword,
    signOutOfAccount
};