import { useEffect, useRef } from 'react';
import Layers from './Layers';
import './_background.scss';

function Background(prop) {
    
    const canvasRef = useRef(null);

    let layers = [];

    function loadImage(imageURL, index) {
        return new Promise(resolve => {
            const image = new Image();
            image.src = imageURL;
            image.onload = function () {
                resolve({ image, position: index });
            }
        });
    }


    function backgroundLoop() {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        layers.forEach(
            (layer) => {
                const { image, x = 0, y = 0, animated, speed, opacity = 1, repeats = false } = layer;


                // MESS WITH LAYER OPACITY
                ctx.save();
                ctx.globalAlpha = opacity;

                ctx.drawImage(image, x, y, canvas.width, canvas.height);

                // WE WILL HAVE THE REPEATING LOGIC RIGHT HERE
                if (repeats) {
                    const direction = speed >= 0 ? 1 : -1;

                    if (x >= canvas.width || x <= -canvas.width) {
                        layer.x = 0;
                    }
                    if (y >= canvas.height) {
                        layer.y = 0;
                    }

                    ctx.drawImage(image, (x - (canvas.width * direction)), 0, canvas.width, canvas.height);
                }

                ctx.restore();

                // IF ANIMATED THEN WE MOVE BABY
                if (animated) {
                    layer.x += 0.25 * speed;
                }

            }
        )
        window.requestAnimationFrame(backgroundLoop);
    }

    useEffect(
        async () => {

            const promises = Layers.map(async ({ url }, index) => {
                return loadImage(url, index);
            });

            const results = await Promise.all(promises);

            results.sort((a, b) => a.position - b.position);

            const canvas = canvasRef.current;
            const ctx = canvas.getContext("2d");
            // Initializing everything first run of code
            results.forEach(
                ({ image }, index) => {

                    const currentLayer = Layers[index];

                    currentLayer.x = 0;
                    currentLayer.y = 0;

                    currentLayer.image = image;

                    layers.push(currentLayer);

                    setTimeout(
                        () => {
                            ctx.imageSmoothingEnabled = false;
                            ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
                        }, 150 * index
                    );

                }
            )


            window.requestAnimationFrame(backgroundLoop);

        }, [canvasRef.current]
    );

    return (
        <div className="background">
            <canvas ref={canvasRef} width="1024" height="560" />
        </div>
    )
}

export default Background;