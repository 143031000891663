import './_navigator.scss';
import { Link } from "react-tiger-transition";
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { BrowserView, MobileView, isBrowser } from 'react-device-detect';

export default function Navigator() {
    const location = useLocation();
    const [currentLocation, setCurrentLocation] = useState('/');

    const [mobileState, setMobileState] = useState({ displayDrawer: false });

    useEffect(() => {
        setCurrentLocation(location.pathname.substr(1));
    }, [location.pathname]);

    const routes = [
        {
            route: 'calendar',
            transition: 'fade',
        }, {
            route: 'weekly',
            transition: 'fade',
        },
        {
            route: 'rules',
            transition: 'fade',
        },
        {
            route: 'home',
            transition: 'rise-top'
        },
        {
            route: 'scareboard',
            transition: 'rise-bottom',
        },
        {
            route: 'arcade',
            transition: 'fade',
        },
    ];

    let filteredRoutes = [];

    routes.forEach(route => {
        if (route.route === "home") {
            if (currentLocation !== "") {
                filteredRoutes.push(route);
            }
        }
        else if (route.route !== currentLocation) {
            filteredRoutes.push(route);
        }
    });

    return (
        <div className="navigator" style={{ backgroundColor: `${currentLocation === 'scareboard' && isBrowser ? 'black' : 'transparent'}` }}>
            <MobileView>
                <div className='top-bar'>
                    <div
                        onClick={() => setMobileState({ ...mobileState, displayDrawer: false })}
                        className="nav-options-container"
                        style={
                            {
                                position: 'fixed',
                                top: mobileState.displayDrawer ? 0 : '100%',
                                zIndex: 5000,
                                height: window.innerHeight - 50,
                                width: window.innerWidth,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'flex-end'
                            }
                        }
                    >
                        {
                            filteredRoutes
                                .map(({ route }) => {
                                    return (
                                        <Link key={route} to={`/${route}`} className="navigator-element">
                                            <div style={{ height: 50, fontSize: 30 }}>{route.toUpperCase()}</div>
                                        </Link>
                                    );
                                })
                        }
                    </div>
                    <div
                        className='nav-button'
                        onClick={() => setMobileState({ ...mobileState, displayDrawer: !mobileState.displayDrawer })}
                    >
                        {currentLocation === "" ? "HOME" : currentLocation.toUpperCase()}
                    </div>
                </div>
            </MobileView>
            <BrowserView>
                <div className="option-panel">
                    {
                        filteredRoutes
                            .map(
                                ({ route, transition }) => {
                                    return (
                                        <Link
                                            key={route}
                                            to={`/${route}`}
                                            className={`navigator-element ${currentLocation === 'scareboard' ? 'scareboard' : 'default'}`}
                                            transition={transition}
                                        >
                                            {route.toUpperCase()}
                                        </Link>
                                    )
                                }
                            )
                    }
                </div>
            </BrowserView>
        </div>
    )
}