import './_scareboard.scss'
import { useEffect, useState, useRef } from 'react';
import { MainContainer, ContentContainer, Navigator } from '../../components';
import { Flex, Box } from 'reflexbox';
import useFetch from '../../hooks/useFetch';
import { isMobile } from 'react-device-detect';

function Scareboard() {

    let { loading, data, error } = useFetch('/getScareboard');

    const [sortState, setSortState] = useState({ currentSorting: 'total', asc: false });
    const [scrollState, setScrollState] = useState({ hitBottom: false });

    const rowContainer = useRef(null);
    const rowRefs = useRef({});


    useEffect(() => {
        if (!loading) {
            setScrollState(0);
        }
    }, [loading]);

    function handleScroll({ target }) {
        const rowHeight = rowRefs.current[Object.keys(rowRefs.current)[0]]?.getBoundingClientRect().height || 0;
        setScrollState({ hitBottom: target.scrollHeight - target.scrollTop - rowHeight / 2 <= target.clientHeight });
    }

    function getSortFunction(sortBy, desc) {
        return (a, b) => {
            let value1 = a[sortBy];
            let value2 = b[sortBy];

            if (sortBy !== 'name') {
                value1 = parseInt(a[sortBy]);
                value2 = parseInt(b[sortBy]);
            }

            let result = 0;
            if (value1 > value2) {
                result = 1;
            } else if (value1 < value2) {
                result = -1;
            } else if (sortState.currentSorting !== 'name') {
                return a.name.toUpperCase().localeCompare(b.name.toUpperCase());
            }
            if (desc) {
                result *= -1;
            }
            return result;
        }
    }

    let columns = [
        {
            title: 'USER',
            width: '30%',
            accessor: 'name'
        },
        {
            title: 'MOVIES',
            width: '30%',
            accessor: 'movies'
        },
        {
            title: 'BONUS',
            width: '28%',
            accessor: 'bonus'
        },
        {
            title: 'TOTAL',
            width: '12%',
            accessor: 'total'
        }
    ]

    if (isMobile) {
        columns = columns.filter((col) => {
            return col.accessor === "total" || col.accessor === "name";
        });
    }

    function renderTableRows(sortedData) {
        return sortedData.map((user) => {

            const currentRowRef = rowRefs.current[user.name];

            let opacity = 1;
            if (currentRowRef && !scrollState.hitBottom) {
                const { y: currentRowYPosition } = currentRowRef.getBoundingClientRect();
                const { y: containerYPosition, height: containerHeight } = rowContainer.current.getBoundingClientRect();
                opacity = 1 - ((currentRowYPosition - containerYPosition) / containerHeight) + 0.15;
            }

            return (
                <Flex key={user.name} className="user row" ref={ref => rowRefs.current[user.name] = ref} style={{ opacity }}>
                    {
                        columns.map(
                            (col) => (
                                <Box className="cell" width={col.width}>
                                    <Flex justifyContent={col.accessor === 'name' ? 'start' : 'center'}>
                                        {user[col.accessor]}
                                    </Flex>
                                </Box>
                            )
                        )
                    }
                </Flex>
            )
        });
    }

    let sortedData = [];

    // Runs the sort every render but who cares
    if (data?.data) {
        sortedData = [...data.data];
        sortedData.sort(getSortFunction(sortState.currentSorting, !sortState.asc));
    }

    return (
        <MainContainer className="scareboard-container">
            <Navigator />
            <ContentContainer isLoading={loading} style={{ overflowX: isMobile ? "hidden" : "scroll" }}>
                <div className="table" style={{ marginTop: 0 }}>
                    <Flex className="header-row">
                        {
                            columns.map(
                                (col) => (
                                    <Box
                                        width={col.width}
                                        className="header"
                                        onClick={
                                            () => {
                                                const isDesc = col.accessor === sortState.currentSorting && sortState.asc;
                                                const userCopyArray = [...sortedData];
                                                userCopyArray.sort(getSortFunction(col.accessor, isDesc));
                                                setSortState({ ...sortState, asc: !isDesc, currentSorting: col.accessor });
                                            }
                                        }
                                    >
                                        <Flex justifyContent={col.accessor === 'name' ? 'start' : 'center'}>
                                            {col.title}
                                        </Flex>
                                    </Box>
                                )
                            )
                        }
                    </Flex>
                    <div ref={rowContainer} onScroll={handleScroll} className="row-container">{renderTableRows(sortedData)}</div>
                </div>
            </ContentContainer>
        </MainContainer>
    )
}

export default Scareboard;