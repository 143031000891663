import './_arcade.scss'
import { MainContainer, ContentContainer, Navigator } from '../../components';
import useAuthHook from '../../hooks/useAuthHook';
import SignUpAndLoginForms from '../../components/game/authentication/SignUpAndLoginForms';
import LeaderBoardModal from '../../components/game/leaderboard/LeaderBoardModal';
import { signOutOfAccount } from '../../firebase';
import { Link } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';
import { useEffect } from 'react';
import { Flex } from 'reflexbox';
import { isBrowser } from 'react-device-detect';

export default function Arcade() {

    // useEffect(() => {
    //     debugger;
    //     const auth = getAuth();
    //     signOut(auth);
    // }, []);

    const { loggedIn, authLoading } = useAuthHook();

    function renderLoginForm() {
        return (
            <SignUpAndLoginForms />
        );
    }


    function renderGames() {
        return (
            <div style={{ fontFamily: 'scorePage', padding: '1rem', width: '100%', maxWidth: 600 }}>
                <h2 style={{ color: 'white' }}>Games</h2>
                <div style={{ display: 'flex', flex: 1, justifyContent: isBrowser ? 'space-around' : 'center', flexWrap: 'wrap' }}>
                    <Link to='/arcade/8BitEvilReturns'>
                        <div className='bitEvilReturnsTile' style={{ height: 225, width: 275, margin: '1rem', display: 'grid', placeItems: 'center'}}>
                        </div>
                    </Link>
                    {
                        isBrowser &&
                        (
                            <Link Link to='/arcade/8BitEvil'>
                                <div className='bitEvilTile' style={{ height: 225, width: 275, margin: '1rem', display: 'grid', placeItems: 'center' }}>
                                </div>
                            </Link>
                        )
                    }
                </div>
            </div >
        )
    }

    return (
        <MainContainer className="arcade-container">
            <Navigator />
            <ContentContainer isLoading={authLoading}>
                {loggedIn ? renderGames() : renderLoginForm()}
            </ContentContainer>
        </MainContainer>
    );
}