import './_home.scss';
import useTimer from '../../hooks/useTimer';
import { MainContainer, ContentContainer, Navigator } from '../../components';
import useFetch from '../../hooks/useFetch';
import { Flex } from 'reflexbox';
import { isMobile } from 'react-device-detect';
import moment from 'moment';
import logo1 from '../../assets/images/logoletters/logo1.png';
import logo2 from '../../assets/images/logoletters/logo2.png';
import logo3 from '../../assets/images/logoletters/logo3.png';
import logo4 from '../../assets/images/logoletters/logo4.png';
import logo5 from '../../assets/images/logoletters/logo5.png';
import logo6 from '../../assets/images/logoletters/logo6.png';
import logo7 from '../../assets/images/logoletters/logo7.png';
import logo8 from '../../assets/images/logoletters/logo8.png';
import logo9 from '../../assets/images/logoletters/logo9.png';
import logo10 from '../../assets/images/logoletters/logo10.png';
import clock from '../../assets/images/clock.gif';
import { useState, useEffect } from 'react'; // added

function Home() {
	const { timer, isPastTargetDate } = useTimer("2022/10/01");

	const { loading, data: calendarData, error } = useFetch('/getCalendar');

	function renderHomePageMovie() {
		const currentDate = new Date().getDate();
		const currentMovie = calendarData?.data[currentDate - 1] || {};
		return (
			<>
				<Navigator />
				<ContentContainer isLoading={loading} style={{ justifyContent: 'center' }}>
					<div className="movie-info-container" overflow={{ padding: '1rem' }}>
						<Flex style={{ height: '100%' }} justifyContent="start" flexDirection="column" alignItems="center">
							<h2 className="standard-text" style={{ fontSize: 40 }}>
								Scareathon 2023
							</h2>
							<div className="date-header">
								{moment().format("MMMM Do")}
							</div>
							<img alt="movie poster" style={{ maxHeight: '50vh', width: "auto", border: '5px solid black', borderRadiusTopLeft: 5, borderRadiusBottomLeft: 5 }} src={currentMovie.url}></img>
							<div className="movie-info-box" style={{ overflow: isMobile ? 'visible' : 'auto' }}>
								<h3 className="standard-text" style={{ fontSize: 40, textAlign: "center" }}>{currentMovie.title}</h3>
								<div className="standard-text">{currentMovie.description}</div>
							</div>
						</Flex>
					</div>
				</ContentContainer>
			</>
		)
	}

	const [logo1class, setLogo1Class] = useState("");
	const [logo2class, setLogo2Class] = useState("");
	const [logo3class, setLogo3Class] = useState("");
	const [logo4class, setLogo4Class] = useState("");
	const [logo5class, setLogo5Class] = useState("");
	const [logo6class, setLogo6Class] = useState("");
	const [logo7class, setLogo7Class] = useState("");
	const [logo8class, setLogo8Class] = useState("");
	const [logo9class, setLogo9Class] = useState("");
	const [logo10class, setLogo10Class] = useState("");

	const logostates = [
		[logo1class, setLogo1Class],
		[logo2class, setLogo2Class],
		[logo3class, setLogo3Class],
		[logo4class, setLogo4Class],
		[logo5class, setLogo5Class],
		[logo6class, setLogo6Class],
		[logo7class, setLogo7Class],
		[logo8class, setLogo8Class],
		[logo9class, setLogo9Class],
		[logo10class, setLogo10Class]
	];

	useEffect(() => {
		const maxNumSecToWait = 5;
		logostates.forEach(([logoclass, setLogoClass]) => {
			setTimeout(() => {
				setLogoClass("vert-move")
			}, Math.random() * maxNumSecToWait * 1000);
		})
	});

	return (
		<MainContainer className={isPastTargetDate ? "home-container" : "css-selector"}>
			{
				isPastTargetDate ? renderHomePageMovie() :
					(
						<div>
							<div className='clockbox'>
								<img alt="clock" src={clock}></img>
							</div>

							<div className='logobox'>
								<img class={logo1class} src={logo1}></img>
								<img class={logo2class} src={logo2}></img>
								<img class={logo3class} src={logo3}></img>
								<img class={logo4class} src={logo4}></img>
								<img class={logo5class} src={logo5}></img>
								<img class={logo6class} src={logo6}></img>
								<img class={logo7class} src={logo7}></img>
								<img class={logo8class} src={logo8}></img>
								<img class={logo9class} src={logo9}></img>
								<img class={logo10class} src={logo10}></img>
							</div>
							<div class="vignette"></div>
						</div>
					)
			}
		</MainContainer>
	);

}

export default Home;