import './_8BitEvilReturns.scss'
import { MainContainer, ContentContainer, Navigator, EightBitEvilReturns } from '../../../components';

function GameRederer() {
    return (
        /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ?
            <EightBitEvilReturns /> :
            <MainContainer className="arcade-container">
                <Navigator />
                <ContentContainer>
                    <EightBitEvilReturns />
                </ContentContainer>
            </MainContainer>
    );
}

export default GameRederer;