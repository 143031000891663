import './_signUpAndLogin.scss';
import { useState, useMemo } from 'react';
import { Flex } from 'reflexbox';
import { signInEmailAndPassword, registerWithEmailAndPassword } from '../../../firebase';
import { getAuth } from 'firebase/auth';
import { isBrowser } from 'react-device-detect';

export default function SignUpAndLoginForms() {

    const baseURL = useMemo(() => {
        if (process.env.NODE_ENV !== 'development') {
            return process.env.REACT_APP_API_URL;
        } else {
            return 'http://localhost:8080';
        }
    }, []);

    const [loading, setLoading] = useState(false);

    const [userFieldsState, setUserFieldsState] = useState(
        {
            login: { userName: '', password: '' },
            signUp: { userName: '', password: '' }
        }
    );


    async function logIn(userName, password) {
        setLoading(true);
        try {
            await signInEmailAndPassword(userName, password);
        } catch {
            console.log('OMG');
        } finally {
            setLoading(false);
        }
    }

    async function signUp(userName, password) {
        setLoading(true);
        try {
            await registerWithEmailAndPassword(userName, password);
            await fetch(`${baseURL}/8bitevilreturns/setUserData`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ userName, silverAmount: 0, unlockedCharacters: [], userId: getAuth().currentUser.uid })
            });
        } catch {
            console.log('OMG');
        } finally {
            setLoading(false);
        }
    }

    function inputChange(e, form) {
        const { target } = e;
        setUserFieldsState({
            ...userFieldsState,
            [form]: {
                ...userFieldsState[form],
                [target.name]: target.value
            }
        })
    }

    return (
        <Flex
            flexDirection="column"
            alignItems={isBrowser ? "center" : "flex-start"}
            className="sing-up-and-login"
            style={
                {
                    padding: '1rem'
                }
            }
        >
            <Flex flexWrap="wrap">
                <Flex flexDirection="column" className="form-container">
                    <div style={{ fontSize: 24, color: 'black', marginBottom: '1rem' }}>Took a wrong turn...?</div>
                    <label>Username</label>
                    <input autocomplete="none" name="userName" type="text" onChange={(e) => inputChange(e, 'signUp')} />
                    <label>Password</label>
                    <input autocomplete="none" name="password" type="password" onChange={(e) => inputChange(e, 'signUp')} />
                    <Flex justifyContent="flex-end">
                        <div className="authentication-button" onClick={() => signUp(userFieldsState.signUp.userName, userFieldsState.signUp.password)}>
                            Sign Up
                        </div>
                    </Flex>
                </Flex>
                <Flex flexDirection="column" className="form-container">
                    <div style={{ fontSize: 24, color: 'black', marginBottom: '1rem' }}>Back for more?</div>
                    <label>Username</label>
                    <input autocomplete="none" name="userName" type="text" onChange={(e) => inputChange(e, 'login')} />
                    <label>Password</label>
                    <input autocomplete="none" name="password" type="password" onChange={(e) => inputChange(e, 'login')} />
                    <Flex justifyContent="flex-end">
                        <div className="authentication-button" onClick={() => logIn(userFieldsState.login.userName, userFieldsState.login.password)}>
                            Login
                        </div>
                    </Flex>
                </Flex>
            </Flex>
            <ul className="tips">
                <li>Make sure there are no spaces or special characters in the username and NO SPACES</li>
                <li>Password must be 6+ characters minimum</li>
                <li>Don't use an important password you've used for other personal accounts</li>
            </ul>
        </Flex>
    )

}