import { useEffect, useState } from 'react';


export default function useFetch(url) {
    let baseURL = '';

    if (process.env.NODE_ENV !== 'development') {
        baseURL = process.env.REACT_APP_API_URL;
    } else {
        baseURL = 'http://localhost:8080';
    }

    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(
        () => {
            let isMounted = true;
            fetch(`${baseURL}${url}`)
                .then(res => res.json())
                .then(data => {
                    setData(data);
                    setError(null);
                })
                .catch(error => {
                    setError(error);
                    setData(null);
                })
                .finally(() => isMounted && setLoading(false));
            return () => (isMounted = false);
        }, [url, baseURL]
    );

    return { data, error, loading };
}