import './_rules.scss'
import { MainContainer, ContentContainer, Navigator } from '../../components';
import { Flex } from 'reflexbox';


export default function Rules() {
    return (
        <MainContainer className="rules-container">
            <Navigator />
            <ContentContainer style={{ alignItems: 'start', padding: '1rem' }}>
                <Flex flexDirection="column" justifyContent="center" className="rules-text">
                    <h2>RULES</h2>
                    <div>Welcome to SCAREATHON 2023! Watch a movie on the day it's scheduled and that's worth 1 point.</div>
                    <div>To actually get that point you'll have to send in photo evidence.</div>
                    <br />
                    <div>WEEKLY: Every Saturday there will also be a theme food and drink to go along with the movie- make either 1 and that's an extra point.</div>
                    <div>Again, you'll have to send photo evidence that you actually did the challenge on that Saturday.</div>
                    <br />
                    <div>Of course, dressing up on Halloween is worth 1 final extra point as well.</div>
                    <div>If you post anything (like the themed foods and drinks or other challenges) online please use #scareathon2023 so everybody can see!</div>
                    <br />
                    <div style={{ marginTop: 10 }}>
                        <Flex justifyContent="flex-end">Happy Halloween</Flex>
                        <Flex justifyContent="flex-end"></Flex>
                    </div>
                </Flex>
            </ContentContainer>
        </MainContainer>
    )
}