import React, { useEffect } from 'react'
import Phaser from 'phaser'
import Game from './scenes/Game';
import Preloader from './scenes/Preloader';
import Title from './scenes/Title';

export default function GameRenderer() {
    const CONFIG = {
        width: "100%",
        height: "100%",
        parent: 'phaser-game',
        type: Phaser.AUTO,
        pixelArt: true,
        physics: {
            default: 'arcade',
            arcade: { debug: false }
        },
        fps: {
            target: 60,
            forceSetTimeOut: true
        },
        scale: {
            mode: Phaser.Scale.FIT,
            autoCenter: Phaser.Scale.CENTER_BOTH,
            width: 1280,
            height: 800,
            min: {
                width: 740,
                height: 360
            },
            max: {
                width: 1280,
                height: 800
            }
        },
        scene: [Preloader, Title, Game]
    }

    useEffect(() => {
        let gameInstance = {};
        setTimeout(() => {
            gameInstance = new Phaser.Game(CONFIG);
            // DO NOT TOUCH BLACK MAGIC
            if (!global.customFunctions) {
                global.customFunctions = {};
            }
            global.customFunctions.destroyGame = () => {
                gameInstance?.destroy();
            }
        }, 1000)
        return () => {
            gameInstance?.destroy();
        }
    }, [])

    return <div id="phaser-game" />;
}