import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useState, useEffect } from 'react';

export default function useAuthHook() {

    const [authState, setAuthState] = useState({ loggedIn: false, authLoading: true });

    useEffect(() => {
        const auth = getAuth();
        
        onAuthStateChanged(auth, (user) => {
            let loggedIn = false;
            if (user) {
                loggedIn = true;
            } else {
                // User is signed out
                loggedIn = false;
            }
            setAuthState({ loggedIn, loading: false });
        });
    }, [])

    return authState;
}