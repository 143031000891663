import './_calendar.scss';
import PropTypes from 'prop-types';
import { Flex } from 'reflexbox';
import moment from 'moment';
import { isBrowser } from 'react-device-detect';
import { useRef, useEffect } from 'react';

export default function Calendar(props) {

    const { numCols, numRows, renderDate } = props;

    const refs = useRef({});

    useEffect(
        () => {
            if (!isBrowser) {
                const currentDate = new Date().getDate();
                refs.current[currentDate].scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }, []);


    function renderWeb() {
        const calendar = [];
        const dates = ['Sat', 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri'];

        const octoberObject = moment('2023-10-01');
        const datesInOctober = octoberObject.daysInMonth();
        const octoberStartDate = octoberObject.format('ddd');
        const columnPositionOfStartDate = dates.indexOf(octoberStartDate);

        const datesInSeptember = moment('2023-09').daysInMonth();
        const previousMonthStart = datesInSeptember - columnPositionOfStartDate + 1;


        const previousMonthDates = [];
        for (let i = previousMonthStart; i <= datesInSeptember; i++) {
            const date = i;
            previousMonthDates.push(
                <div className="date-container invalid">
                    <div className="invalid-date">
                        {date}
                    </div>
                </div>
            )
        }

        let dayCount = 1;

        for (let i = 0; i < numRows; i++) {
            let dates = [];
            if (i === 0) {
                dates = previousMonthDates;
            }
            const datesLen = dates.length;
            for (let j = 0; j < numCols - datesLen; j++) {
                dates.push(
                    <div className={`date-container ${dayCount > datesInOctober ? 'invalid' : ''}`}>
                        {
                            renderDate ?
                                renderDate(dayCount <= datesInOctober ? dayCount : null, dayCount - 1) :
                                dayCount <= datesInOctober && dayCount
                        }
                    </div>
                )
                dayCount++;
            }
            calendar.push(
                <div className="calendar-row">
                    {dates}
                </div>
            )
        }

        return (
            <div>
                <Flex className="calendar-row" style={{ borderRadius: 5 }}>
                    {
                        dates.map((date, index) => {
                            return (
                                <div
                                    className='header-col'
                                    style={
                                        {
                                            borderRight: index === dates.length - 1 ? '1px solid black' : 'none',
                                            borderLeft: index === 0 ? '1px solid black' : 'none',
                                            borderTopLeftRadius: index === 0 ? 7.5 : 0,
                                            borderBottomLeftRadius: index === 0 ? 7.5 : 0,
                                            borderTopRightRadius: index === dates.length - 1 ? 7.5 : 0,
                                            borderBottomRightRadius: index === dates.length - 1 ? 7.5 : 0,
                                        }
                                    }>
                                    {date}
                                </div>
                            )
                        })
                    }
                </Flex>
                {calendar}
            </div>
        )
    }


    function renderMobile() {
        const octoberObject = moment('2023-10-01');
        const datesInOctober = octoberObject.daysInMonth();
        const octoberStartDate = octoberObject.format('ddd');
        const dates = ['Sat','Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri'];

        const currentDate = new Date().getDate();
        let startIndex = dates.indexOf(octoberStartDate);
        const results = [];

        for (let i = 0; i < datesInOctober; i++) {
            results.push(
                <div
                    ref={ref => refs.current[i + 1] = ref}
                    style={
                        {
                            padding: '1rem',
                            transform: `scale(${(i + 1) === currentDate ? 1.2 : 1})`
                        }
                    }
                >
                    {props.renderCustomCalendarDateMobile(`${dates[startIndex]}`, i)}
                </div>
            );
            startIndex = (startIndex + 1) % dates.length;
        }


        return <Flex flexDirection="row" justifyContent="center" width="100%" flexWrap='wrap'>{results}</Flex>;
    }

    return isBrowser ? renderWeb() : renderMobile();
}

Calendar.propTypes = {
    numRows: PropTypes.number,
    numCols: PropTypes.number,
    renderDate: PropTypes.func,
    data: PropTypes.object
}

Calendar.defaultProps = {
    numRows: 6,
    numCols: 7,
    renderDate: null
}