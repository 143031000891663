import './_weekly.scss'
import myimage from '../../assets/images/saturday5.png';
import { MainContainer, ContentContainer, Navigator } from '../../components';
import { Flex } from 'reflexbox';
import { isMobile } from 'react-device-detect';

export default function Weekly() {
    return (
        <MainContainer className="weekly-container">
            <Navigator />
            <ContentContainer>
                <Flex style={{ width: '100vw', height: 'auto' }} justifyContent={isMobile ? 'start' : 'center'}>
                    {/* <img alt="img" style={{ maxHeight: '100%', maxWidth: 'auto' }} src={myimage}></img> */}
                </Flex>
            </ContentContainer>
        </MainContainer>
    )
}