import PropTypes from 'prop-types';
import { Flex } from 'reflexbox';
import Loading from '../loading/Loading';
import './_containers.scss';

function MainContainer(props) {
    const { className } = props;
    return (
        <div className={`main-container ${className}`}>
            {props.children}
        </div>
    )
}

function ContentContainer(props) {
    const { className = '', isLoading, style } = props;

    return (
        <div className={`content-container ${className}`} style={style}>
            {
                isLoading ?
                    <Flex justifyContent="center" alignItems="center">
                        <Loading />
                    </Flex> :
                    props.children
            }
        </div>
    )
}

ContentContainer.propTypes = {
    isLoading: PropTypes.bool,
}

ContentContainer.defaultProps = {
    isLoading: false,
}

export { MainContainer, ContentContainer };