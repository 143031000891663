import './App.scss';
import './firebase';
import 'react-tiger-transition/styles/main.min.css';

import {
  BrowserRouter as Router, Redirect,
} from "react-router-dom";

import { Navigation, Route, glide, fade } from "react-tiger-transition";
import { Home, Scareboard, Calendar, Arcade, Weekly, Rules, Winners, EightBitEvil, EightBitEvilReturns } from './views';
import { Loading } from './components';
import { useEffect } from 'react';
import useAuthHook from './hooks/useAuthHook';


// inject glide styles
glide({
  name: 'rise-top',
  direction: 'bottom'
});

glide({
  name: 'rise-bottom',
  direction: 'top'
});

fade({
  name: 'fade',
  direction: 'left',
});


function App() {

  useEffect(() => {
    let timeoutId = null;
    const documentHeight = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        const doc = document.documentElement;
        doc.style.setProperty('--doc-height', `${window.innerHeight}px`);
      }, 200);
    };

    window.addEventListener('resize', documentHeight);
    documentHeight();
  }, []);



  let appRender = <Loading />;
  appRender = (
    <Router>
      <Navigation>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/scareboard">
          <Scareboard />
        </Route>
        <Route exact path="/calendar">
          <Calendar />
        </Route>
        <Route exact path="/rules">
          <Rules />
        </Route>
        <Route exact path="/arcade">
          <Arcade />
        </Route>
        <SecureRoute exact path="/arcade/8BitEvil" component={EightBitEvil} />
        <SecureRoute exact path="/arcade/8BitEvilReturns" component={EightBitEvilReturns} />
        <Route exact path="/weekly">
          <Weekly />
        </Route>
      </Navigation>
    </Router>
  );

  return (
    <div className="app-container">
      {appRender}
    </div>
  )
}


function SecureRoute({ component: Component, ...rest }) {
  const { loggedIn } = useAuthHook();

  return (
    <Route
      {...rest}
    >
      {
        loggedIn ? (
          <Component />
        ) : (
          <Redirect to="/arcade" />
        )
      }
    </Route>
  )
}

export default App;