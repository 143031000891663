import './_winners.scss';
import useFetch from '../../hooks/useFetch';
import { MainContainer, ContentContainer } from '../../components';
import { Flex, Box } from 'reflexbox';

function Winners() {

    const { loading, data: winnersData, error } = useFetch('/getWinners');
    const colorShade = (col, amt) => {
        col = col.replace(/^#/, '')
        if (col.length === 3) col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2]

        let [r, g, b] = col.match(/.{2}/g);
        ([r, g, b] = [parseInt(r, 16) + amt, parseInt(g, 16) + amt, parseInt(b, 16) + amt])

        r = Math.max(Math.min(255, r), 0).toString(16)
        g = Math.max(Math.min(255, g), 0).toString(16)
        b = Math.max(Math.min(255, b), 0).toString(16)

        const rr = (r.length < 2 ? '0' : '') + r
        const gg = (g.length < 2 ? '0' : '') + g
        const bb = (b.length < 2 ? '0' : '') + b

        return `#${rr}${gg}${bb}`
    }
    const imageHeight = 300;
    return (
        <MainContainer>
            <ContentContainer isLoading={loading} className="winners-page">
                <Flex flexDirection="column">
                    <Flex flexDirection="column" style={{ minWidth: 1000, width: '50vw' }}>
                        <h1 style={{ color: 'white' }}>Thank you all for participating!</h1>
                        <h2 style={{ color: 'white', fontWeight: 'normal' }}>
                            Hope you all had as much fun as I did during these spooky times! Hope to see you again next year!
                        </h2>
                    </Flex>
                    <Flex>
                        <div style={{ padding: '0.5rem' }}>
                            <p>Matt</p>
                            <img style={{ height: imageHeight, width: 'auto', borderRadius: 7.5 }} src="https://cdn.discordapp.com/attachments/664329856632356864/904578474965073970/image0.jpg" alt="" />
                        </div>

                        <div>
                            <h3>Winners</h3>
                            <Flex flexDirection="column" style={{ fontSize: 24, borderRadius: 7.5, borderCollapse: 'separate', overflow: 'hidden' }} width={600}>
                                {
                                    winnersData?.data.map(({ name, total, position }, index) => {
                                        let left = (
                                            <div>
                                                {name}
                                            </div>
                                        )
                                        let right = (
                                            <div>
                                                {total}
                                            </div>
                                        )
                                        return (
                                            <Flex
                                                justifyContent="space-between"
                                                style={
                                                    {
                                                        padding: '1rem',
                                                        background: colorShade('#E0574F', -30 * (position - 1)),
                                                        borderBottom: index !== winnersData?.data.length - 1 ? '1px solid black' : ''
                                                    }
                                                }
                                            >
                                                <Box width={1}>{position}</Box>
                                                <Box width={1} style={{ textAlign: 'center', overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>{left}</Box>
                                                <Box width={1} style={{ textAlign: 'end' }}>{right}</Box>
                                            </Flex>
                                        )
                                    })
                                }
                            </Flex>
                        </div>
                        <div style={{ padding: '0.5rem' }}>
                            <p>Joe & Julie</p>
                            <img style={{ height: imageHeight, width: 'auto', borderRadius: 7.5 }} src="https://cdn.discordapp.com/attachments/664329856632356864/904578742335201351/IMG_2818.png" alt="" />
                        </div>
                    </Flex>
                    <div style={{marginTop: '2rem'}}>
                        <h3 style={{letterSpacing: '0.15rem'}}>Ties are sorted alphabetically!</h3>
                        <h3 style={{letterSpacing: '0.15rem'}}>Prizes will be mailed out eventually (LOL!)</h3>
                    </div>
                </Flex>
            </ContentContainer>
        </MainContainer>
    );

}

export default Winners;
